* {
  margin: 0;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #ffffffff;
  min-height: 100vh;
  min-width: 100vw;
  font-size: calc(10px + 2vmin);
}

a:link {
  color: #413f42
}

a:visited {
  color: #16003b
}
